.rc-time-picker-panel {
    z-index: 9999999 !important;
    width: 190px !important;
    display: grid !important;
};

.rc-time-picker-panel-inner {
    display: grid !important;
}
 
.MuiFormLabel-root {
    color: gray;
}

.MuiFormLabel-root .Mui-focused {
    color: #b71c1c !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: gray !important;
}

.css-u9osun.Mui-focused {
    color: gray !important;
}

.MuiFormControl-fullWidth {
    min-width: 120px !important
}

.css-r93niq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #b71c1c !important;
    color: white !important;
}

.paginationPage {
    display: flex;
    margin: auto;
}

.image-upload > input
{
    display: none;
}

.MuiIconButton-label {
    color: darkgray !important;
}

