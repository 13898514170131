@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url('ProximaNovaFont.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'century_gothic';
    src: url('../fonts/century-gothic/Century-Gothic.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'century_gothic_bold';
    src: url('../fonts/century-gothic/Century-Gothic-Bold.otf');
    font-weight: normal;
    font-style: normal;
}

.loader {
    width: 100%;
    height: 92vh;
    background: url('../images/loading.gif') 50% 50% no-repeat #fcfcfc;
    opacity: 1;
    z-index: 1300;
}

.upload_loader {
    width: 100%;
    height: 92vh;
    background: url('../images/upload.gif') 50% 50% no-repeat #fcfcfc;
    opacity: 1;
    z-index: 1300;
}

.LoadingData {
    width: 100%;
    height: 92vh;
    background: url('../images/loader.gif') 50% 50% no-repeat #fcfcfc;
    opacity: 1;
    z-index: 1300;
}

html {
    font-family: "proxima_nova_rgregular";
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: "proxima_nova_rgregular";
    height: 100%;
    width: "100%";
    background-color: #f6f7ff;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: 40px;
}

.react-tagsinput {
    border-radius: 4px !important;
    background-color: #f4f2f26b !important;
    padding: 3px 10px !important;
}

.react-tagsinput-tag {
    background-color: #dddddd !important;
    border: 1px solid #dddddd !important;
    color: #000000 !important;
    font-size: 15px !important;
    margin-right: 5px !important;
    margin-bottom: 1px !important;
    padding: 5px !important;
}

.react-tagsinput-input {
    background: transparent !important;
    border: 0 !important;
    color: #777 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    outline: none !important;
    padding: 7px 0px !important;
    width: 80px !important;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: darkgray;
    border-radius: 10px;
}

.DateRangePicker__CalendarSelection {
    background-color: #005F73 !important;
    border: 1px solid #005F73 !important;
}

.MuiFormHelperText-root {
    color: red !important
}

.css-1sn4lm3-MuiTypography-root {
    color: black !important
}

.rmdp-range {
    background-color: #005F73 !important;
}

.rmdp-week-day {
    color: #005F73 !important;
}

.rmdp-day.rmdp-today span {
    background-color: #005F73;
}

.input-range__track--active {
    background-color: #005F73 !important;
}

.input-range__slider {
    background-color: #005F73 !important;
    border: 1px solid #005F73 !important;
}


  .MuiInputAdornment-positionEnd {
    margin-left: 0px !important;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px !important;
  }
  .Connect\(MainLayout\)-content-2.Connect\(MainLayout\)-contentShift-3{
    background-color: #eff6fc;
  }
  .MuiGrid-root.Connect\(Dashboard\)-root-84.MuiGrid-container.MuiGrid-spacing-xs-2{
    background-color: #eff6fc;
  }
  .MuiPaper-root.MuiCard-root.makeStyles-card-88.MuiPaper-elevation1.MuiPaper-rounded{
    box-shadow: 0 3px 20px #0000000b;
    background-color: white!important;
    height: 150px;
    /* transition: 0.5s; */
  }
  .MuiPaper-root.MuiCard-root.makeStyles-card-88.MuiPaper-elevation1.MuiPaper-rounded img{
    transition: 1.5s;
  }
  .MuiPaper-root.MuiCard-root.makeStyles-card-88.MuiPaper-elevation1.MuiPaper-rounded:hover img{
    transform: rotateY(180deg);
  }
  .MuiPaper-root.MuiCard-root.makeStyles-card-88.MuiPaper-elevation1.MuiPaper-rounded:hover{
    /* background-color: #bdd1ff!important; */
    border: 1px solid #33333336;
  }
  
  
  .MuiPaper-root.MuiCard-root.makeStyles-card-88.MuiPaper-elevation1.MuiPaper-rounded.MuiPaper-root.MuiCard-root.makeStyles-card-88.MuiPaper-elevation1.MuiPaper-rounded:hover{

  }
  .MuiDialog-root {
   margin:unset!important;
}
.MuiDialogContent-dividers{
    border: none!important;
}
svg.MuiSvgIcon-root{
    font-size: 1rem;
}
.asad img.MuiAvatar-img {
    filter: brightness(0.5);
}

/* .asad .makeStyles-ListItem-105:hover {
    transform: matrix(1, 0, 0, 1, 0, 0);
    background-color: #2759cd;
    box-shadow: 0 3px 20px #0000000b;
    border-radius: 100px;
} */

.asad .makeStyles-ListItem-105:hover img.MuiAvatar-img {
    filter: unset;
}
.asad .makeStyles-ListItem-105:hover p {
    color:white;
}
.Connect\(SchedulerLayout\)-root-1 img.Connect\(Header\)-onelogo-79 {
    display: none;
}

.Connect\(SchedulerLayout\)-root-1 span.Connect\(Header\)-logotitle-75{
    display: none;
}

/* .Connect\(RoutineLayout\)-contentShift-135{
    background-color: #F7F8FC !important;
}
.Connect\(RoutineLayout\)-contentShift-3{
    background-color: #F7F8FC !important; 
} */

.scheduler-dashboard{
    background-color: #F6F6F6;
    border-radius: 12px 0px 0px 0px;
}
.scheduler-dashboard tr td:first-child { border-top-left-radius: 10px;border-bottom-left-radius: 10px; border: unset!important;}
.scheduler-dashboard tr:first-child td:last-child { border-top-right-radius: 10px; border: unset;}

.scheduler-dashboard tr:last-child td:first-child { border-bottom-left-radius: 10px; border: unset;}
.scheduler-dashboard tr:last-child td:last-child { border-bottom-right-radius: 10px; border: unset;}

.scheduler-dashboard tr:first-child td { border: unset; }
.scheduler-dashboard tr td:first-child { border-left-style: solid; }


.scheduler-dashboard tr th:first-child { border-top-left-radius: 10px;border-bottom-left-radius: 10px; border: unset!important;}
.scheduler-dashboard tr:first-child th:last-child { border-top-right-radius: 10px; border: unset;}

.scheduler-dashboard .MuiSwitch-track {
    background-color: #9f0d9c7a;
}
.rmdp-day.rmdp-today span {
    background-image: linear-gradient(45deg, #AA076B, #9F0D9C)
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: white!important;
    opacity: 1;
}