.payrollMain {
    padding: 10px 15px !important;
    border: 1px solid #E3E0E0;
    display: flex;
    margin: 2px;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.verticalLine {
    border-left: 1px solid gray;
    height: 50px;
}

.ActiveverticalLine {
    border-left: 1px solid gray;
    height: 50px;
    margin-left: 90px;
}

.approvalMain {
    display: flex;
    justify-content: center;
    margin-bottom: 2px !important;
    align-items: flex-end;
}

.approvalIcon {
    margin-left: 50px;
    height: 30px;
}

.approvalButton {
    background: gainsboro !important;
    border-radius: 20px !important;
    padding: 6px 24px !important;
}

.inst-btn {
    text-transform: uppercase;
    margin: 60px 0 0 320px;
    /* background: gainsboro !important; */
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 20px !important;
    outline: 0;
}

.inst-btns {
    text-transform: uppercase;
    margin: 60px 0 0 320px;
    background: gainsboro !important;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 20px !important;
    outline: 0;
}

.inst-btn {
    font-size: 26px;
    color: white;
    padding: 5px;
    margin: 0;
    font-family: "arial";
    border: 0;
}

.Account-btn {
    background-color: #217561 !important;
    color: white !important;
    border-radius: 25px !important;
    font-size: 14px !important;
    padding: 6px 17px !important;
}

.wrapper:after {
    content: " ";
    width: 100px;
    height: 2px;
    margin: -4px 6px;
    vertical-align: super;
    background-color: grey;
    display: inline-block;
}

.ActiveLogMain {
    display: flex;
}

.ActiveTitle {
    color: #645E5E;
    font-weight: 700;
}

.ActiveComment {
    color: #A5A1A1;
}

.editIcon {
    margin-top: 20px !important;
    margin-left: 6px !important;
    border: 1px solid !important;
}

.titleColumn {
    background-color: #000000;
    color: white;
    padding: 10px;
}

.paraEdit {
    font-weight: 500 !important;
    padding: 10px;
    font-size: 14px !important;
}

.titlecloumnName {
    line-height: 3 !important;
}

.ms-auto {
    margin-left: auto;
}

.editSaveButton {
    background-color: #000000 !important;
    color: white !important;
    padding: 10px 37px !important;
}