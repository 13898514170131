 .paper{
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    width: 89%; }
 
 .paper::-webkit-scrollbar{
    display: none;
}