.questionnaire {
    padding: 20px;

    p,
    label {
        font-size: 14px;
    }

    select,
    option,
    input {
        font-size: 13px;
    }

    .top-label {
        color: #535f6a;
    }

    .bg-theme {
        background-color: #2f317c;
    }

    .btn-save {
        padding: 5px;
        height: fit-content;
        border: 0px;
    }

    .dark-btn {
        font-size: 11px !important;
        font-weight: bold;
        background-color: #273646;
        padding: 3px 10px;
        border-radius: 3px;
        color: #fff;
        cursor: pointer;
        outline: none !important;
    }

    .card-body {
        padding: 0px;
    }

    .left-card-header {
        border-bottom: 1px solid #eaeaea;
        padding: 6px 10px;
        font-size: 14px;
        color: #263746;
        font-weight: bold;
        align-items: center;
    }

    .top-border {
        border: 1px solid #eaeaea;
    }

    .arrow-icon {
        margin-left: auto;
        color: #c2c2c2;
        cursor: pointer;
    }

    .basic-fields .basic-field-col {
        padding-left: 8px;
        padding-right: 8px;
    }

    .basic-field-box {
        background: #fff;
        border: 1px dashed #d3d3d3;
        border-radius: 2px;
        padding: 5px;
        font-size: 13px;
        margin-top: 7px;
        margin-bottom: 7px;
        color: #5a5a5a;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 400;
    }

    .basic-field-box:hover {
        border: 1px dashed #5cc6a3;
    }

    .responses {
        max-height: 180px;
        overflow-y: scroll;

        .section {
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 8px;
            padding-right: 8px;

            .button {
                border-radius: 14px;
                padding: 0 8px;
                display: inline-block;
                color: #344049;
                background-color: #fff;
                font-size: 12px;
                margin-right: 5px;
                min-width: 40px;
                text-align: center;
                font-weight: bold;
                margin-top: 4px;
                margin-bottom: 4px;
                word-break: break-all;
            }
        }
    }

    .questionnaire-section {
        .section-card {
            .title-row {
                padding: 5px 10px;
                display: flex;
                align-items: center;

                .round-div {
                    background-color: #5cc6a3;
                    border-radius: 50%;
                    width: 22px;
                    height: 22px;
                    text-align: center;
                    cursor: pointer;

                    .right-icon {
                        margin-top: -6px;
                        margin-left: 3px;
                    }

                    .down-icon {
                        margin-top: -3px;
                    }
                }

                .section-input {
                    border-radius: 0px;
                    border: 0px;
                }
            }

            .subsection-div {
                padding-left: 15px;
            }
        }

        .questions-div {
            padding-left: 15px;

            .question-field {
                font-size: 14px;
                border: 0px;
            }

            .inactive-bg {
                background-color: #f8f8f8;
            }

            .basic-field-col {
                padding-left: 10px;
                padding-bottom: 5px;
            }
        }

        .add-question-btn {
            font-size: 11px !important;
            font-weight: bold;
            background-color: #273646;
            padding: 3px 10px;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            outline: none !important;
            width: fit-content;
        }
    }

    .right-card {
        label {
            font-size: 14px;
            margin: 0px;
        }

        .checkbox-label {
            font-size: 14px;
            cursor: pointer;
            display: flex;

            .checkbox {
                height: 16px;
                line-height: 0;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 8px;
                order: 0;
                position: relative;
                vertical-align: middle;
                white-space: nowrap;
                width: 16px;
                flex-shrink: 0;
            }
        }

        .right-responses-section {
            .body {
                .internal-card {
                    background-color: #f6f7f7;
                    padding: 10px;
                    margin-bottom: 8px !important;
                    border: 1px solid #ddd;

                    .form-control {
                        font-size: 14px;
                    }

                    .display-flex {
                        display: flex;

                        label {
                            margin: 0px;
                        }
                    }

                    .score-input {
                        width: 50px;
                        border: 1px solid #d8d5d5;
                        border-radius: 3px;
                        padding: 2px 5px !important;
                        font-size: 14px;
                        height: 22px;
                    }
                }

                textarea,
                select,
                option {
                    font-size: 14px;
                }

                .Add-photo {
                    position: relative;
                    display: flex;
                    padding-bottom: 10px;

                    .ng-star-inserted {
                        font-size: 13px;
                        line-height: 1.42857;
                        color: #414244;
                        font-weight: 400;

                        .AddBtn {
                            cursor: pointer;
                            background-color: #5cc6a3;
                            padding-top: 20px;
                            width: 82px;
                            height: 82px;
                            border-radius: 5px;
                            color: #fff;
                            font-weight: bold;
                            text-align: center;
                            cursor: pointer;
                            display: inline-block;
                            margin-right: 10px;
                            position: relative;
                            overflow: hidden;

                            div {
                                height: 82px;

                                img {
                                    height: 82px;
                                    background-color: #e8e8e8;
                                    border-radius: 5px;
                                }
                            }

                            .DeleteLayout {
                                width: 100%;
                                background: black;
                                opacity: 0.7;
                                position: absolute;
                                height: 100%;
                                border-radius: 5px;
                                top: 0;

                                svg {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }

                            .DeleteLayout:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }

                .locations {
                    border: 1px solid rgba(0, 0, 0, .1);
                    background-color: #f6f7f754;
                    margin-left: 7.5px;
                    margin-right: 7.5px;

                    .location-row {
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-top: 7px;
                        padding-bottom: 7px;
                    }

                    .roles {
                        background-color: white;

                        .roles-row {
                            padding-left: 15px;
                            padding-right: 15px;
                            padding-top: 7px;
                            padding-bottom: 7px;
                        }

                        .users {
                            background-color: white;

                            .users-row {
                                padding-left: 15px;
                                padding-right: 15px;
                                padding-top: 7px;
                                padding-bottom: 7px;
                            }
                        }
                    }
                }
            }
        }
    }
}